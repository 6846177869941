var pageState = { foo: "bar" };


document.onload = function () {
    $(window).trigger('resize'); // Force IE to set the right height
}

$(document).foundation({
  equalizer : {
    // Specify if Equalizer should make elements equal height once they become stacked.
    equalize_on_stack: true
  },
	offcanvas: {
                open_method: 'overlap'
            }
});


$(document).ready(function () {

    $('.vertical-center').each( function () {
        $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });
	
	/** CV UPLOADER BUTTON **/
$("input:file").change(function (){
var filename = $("input:file").val().replace(/C:\\fakepath\\/i, '');
 $(".fileUpload span").html(filename);
 $(".fileUpload").addClass("uploaded");
});

	
	var maxHeight = -1;

$('.offers-grid .offer-item .inner').each(function() {
     maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
   });

 $('.offers-grid .offer-item .inner').each(function() {
     $(this).height(maxHeight);
   });
	
	$( ".christmas-animation" ).hover(
  function() {
    $( '.christmas-animation .hover' ).fadeIn(1300);
	$( '.christmas-animation .front' ).fadeOut(1300);
  }, function() {
   $('.christmas-animation .hover').fadeOut(1300);
$( '.christmas-animation .front' ).fadeIn(1300);
  }
);


$( ".link-row" ).each(function() {
		if ($(this).find ('.book-link').length > 0){
		$(this).find ('.read-link').addClass('medium-6');
		$(this).find ('.book-link').addClass('medium-6');
	} else {		
		
		$(this).find ('.read-link').addClass('medium-12');
	}
	});
	
	

	
	$( ".link-row" ).each(function() {
		if ($(this).find ('.read-link').length > 0){
		$(this).find ('.read-link').addClass('medium-6');
		$(this).find ('.book-link').addClass('medium-6');
	} else {		
		
		$(this).find ('.book-link').addClass('medium-12');
	}
	});



	$('.accordion').accordiom({
        showFirstItem: true,
        afterChange: function () {
            //$(window).trigger('resize');
			var accordionHeight = $('.detail-text').height();
			//alert(accordionHeight);
			$('.detail-slide.slick-slide img').css('padding-bottom', accordionHeight + 'px');
			$('#detail-content .slick-list').css('overflow-y', 'scroll');
        }
    });




    // Navigation
    $('.primary-nav-toggle').on('click', function () {
        $(this).toggleClass('active');
        $(this).next().slideToggle(500, function () {
            $(document).foundation('equalizer', 'reflow');
        });
        return false;
    });









    // Homepage slider
    if (($('#home-content-inner').length > 0) && (!$('#home-content').hasClass('awesome-wall-wrapper'))) {
        /*$('#home-content-inner').maximage({
            cycleOptions: {
                speed: 800,
                timeout: 6000,
                prev: '.home-slider-prev',
                next: '.home-slider-next'
            }
        });
        $('video, object').maximage('maxcover');*/
		/*$('video').videocover({
			minWidth: 320
		});*/
		
		$('#home-content-inner').reformSlider({
				arrows: true,
				speed: 6000,
				animationSpeed: 1000
			});
			
			
    }

    //$('.home-slider-prev').on('click', function () {
    //    $('#home-content-inner').cycle('prev');
    //    return false;
    //});

    //$('.home-slider-next').on('click', function () {
    //    $('#home-content-inner').cycle('next');
    //    return false;
    //});




    // Landing page slider
    if ($('#landing-content-inner').length > 0) {
        $('#landing-content-inner').maximage({
            cycleOptions: {
                speed: 800,
                timeout: 8000
            }
        });
    }
	
	$(document.body).on('click', '#landing-content-inner .mc-image', function () { // Click on whole image to slide over
		$('a.landing-slider-next').trigger('click');
	});

    $('.landing-slider-next').on('click', function () {
        if (($(this).hasClass('black')) || ($(this).parent().hasClass('rooms-landing'))) {
            return true;
        }
        var arrow = this;
        $(arrow).hide();
        $('#landing-panels').addClass('open');
        setTimeout(function () {
            $(arrow).addClass('black');
            $(arrow).fadeIn();
            $('.landing-slider-prev').fadeIn();
        }, 1000);
        return false;
    });

    // Skip to grid when URL has the ?back=1 parameter
    if (window.location.href.indexOf('back=1') > -1) {
        $('#landing-panels').addClass('no-transition').addClass('open');
        $('.landing-slider-prev').show();
        $('.landing-slider-next').addClass('black').show();
    }

    $('.landing-slider-prev').on('click', function () {
        if ($(this).parent().hasClass('rooms-landing')) {
            return true;
        }
        $('#landing-panels').removeClass('open');
        $('.landing-slider-prev').hide();
        $('.landing-slider-next').removeClass('black');
        return false;
    });

    $('.landing-grid-item').on('mouseenter', function () {
        $(this).addClass('hover');
    });
    $('.landing-grid-item').on('mouseleave', function () {
        $(this).removeClass('hover');
    });

    /*setTimeout(function () {
		//$('#landing-panels').addClass('open');
		$('.landing-slider-next').trigger('click');
	}, 5000);*/




    // Detail pages
    setupSlider($('.detail-slider'));

    var initialSlide = $('#detail-content').attr('data-first-slide');
    if (typeof initialSlide == 'undefined') {
        initialSlide = 0;
    }

    $('#detail-content').slick({
        dots: false,
        fade: false,
        autoplay: false,
        infinite: false,
        arrows: false,
		draggable: false,
        adaptiveHeight: true,
        initialSlide: initialSlide * 1,
        useCSS: false, // hopefully stops glitches
        accessibility: false // stops slide jumping to top of image on change
    });

    $('#detail-content').on('afterChange', function (event, slick, currentSlide) {
        $(window).trigger('resize'); // Force browsers to set the right height
        $("div.book-now a").attr("href", $("#detail-content .slick-active #hiddenBookingLink").val());
    });

    // Manual previous and next buttons
    $(document.body).on('click', '.detail-next', function () {
        $('#detail-content').slick('slickNext');
        history.replaceState(pageState, 'Next Slide', $(this).attr('href'));
        // Load next slide
        setTimeout(loadNextPageAfterSlide, 500);
        return false;
    });
    $(document.body).on('click', '.detail-prev', function () {
        $('#detail-content').slick('slickPrev');
        history.replaceState(pageState, 'Previous Slide', $(this).attr('href'));
        // Load prev slide
        setTimeout(loadPrevPageAfterSlide, 500);
        return false;
    });

    // Allow arrow key navigation
    $(document).keydown(function (e) {
        switch (e.which) {
            case 37: // left
                $('.detail-content-container.slick-slide.slick-current').find('.detail-prev').trigger('click');
                break;

            case 39: // right
                $('.detail-content-container.slick-slide.slick-current').find('.detail-next').trigger('click');
                break;

            default: return;
        }
        e.preventDefault();
    });

    // When swiping, load the next and prev pages as the Next/Prev buttons are not clicked
    $('#detail-content').on('swipe', function (event, slick, direction) {
        // right == prev
        // left == next
        if (direction == 'right') {
            setTimeout(loadPrevPageAfterSlide, 500);
        } else if (direction == 'left') {
            setTimeout(loadNextPageAfterSlide, 500);
        }
    });


    // Load the left and right pages
    loadLeftAndRightPages();



    // Team
    $('.team-person a').fancybox({
        type: 'ajax',
        wrapCSS: 'team-popup',
        padding: 20,
        beforeShow: function (current, previous) {
            $('.detail-slider').slick({
                fade: true,
                speed: 1000
            });
        }
    });



    // Forms
    $('#uploadBtn').on('change', function () {
        $('#uploadFile').val($(this).val());
    });


    // Fixes the height of the burger when open
    $('#main-header a.left-off-canvas-toggle').on('click', function () {
        $('body').addClass('burger');
    });
    $('.left-off-canvas-menu-inner a.left-off-canvas-toggle').on('click', function () {
        $('body').removeClass('burger');
    });


    // Misc
    $('a.open-favourites').on('click', function () {
        $('.favourites-login, .favourites-panel').fadeToggle();
        return false;
    });


    $('.toggle-search').on('click', function () {
        $('.burger-search').slideToggle();
        return false;
    });

    $('.search-reveal-more').on('click', function () {
        $(this).fadeOut(500);
        $('.search-results .hide').fadeIn(500);
        return false;
    });

    $('#timeline-see-more .see-more').on('click', function () {
        //$('#timeline-see-more').fadeOut();
        $("html, body").animate({ scrollTop: $(window).scrollTop() + ($(window).height() - 100) }, 800);
        return false;
    });

    $(document.body).on('click', '.detail-share-social a', function () {
        //		$(this).parent().next().animate({width: 'toggle'}, 300);
        $(this).parent().next().toggleClass('open');
        return false;
    });


    $('a.open-follow-us').on('click', function () {
        $('.follow-us-wrapper').fadeIn().load($(this).attr('href'));
        return false;
    });


  /**  $(document.body).on('click', '.detail-cta a', function () {

        if ( $(window).innerWidth() <= 680 ) {
            var widgetStyles = 'style="bottom: auto; left: auto; right: -10px; top: -500px; z-index: 70000;"';
        } else {
            var widgetStyles = 'style="bottom: auto; left: auto; right: 110px; top: -450px; z-index: 70000;"';
        }

        if ($(this).attr('href') == '#book-table') {
            $(this).before('<div id="restaurant-widget" ' + widgetStyles + '><a class="restaurant-widget-close" href="#" onclick="$(\'#restaurant-widget\').fadeOut(); return false;">Close</a><iframe src="https://booking.resdiary.com/widget/Standard/ClivedenDiningRoom/325" allowtransparency="true" frameborder="0" style="width:100%; border:none; max-width: 540px; height: 500px; "></iframe></div>');
            $('#restaurant-widget').fadeIn();
            return false;
        } else if ($(this).attr('href') == '#book-table-astor') {
            $(this).before('<div id="restaurant-widget" ' + widgetStyles + '><a class="restaurant-widget-close" href="#" onclick="$(\'#restaurant-widget\').fadeOut(); return false;">Close</a><iframe src="https://booking.resdiary.com/widget/Standard/TheAstorGrill/7647" allowtransparency="true" frameborder="0" style="width:100%; border:none; max-width: 540px; height: 500px; "></iframe></div>');
            $('#restaurant-widget').fadeIn();
            return false;
        } else if ($(this).attr('href') == '#book-table-boats') {
            $(this).before('<div id="restaurant-widget" ' + widgetStyles + '><a class="restaurant-widget-close" href="#" onclick="$(\'#restaurant-widget\').fadeOut(); return false;">Close</a><iframe src="https://booking.resdiary.com/widget/Standard/ClivedenHouseFlotilla/7648" allowtransparency="true" frameborder="0" style="width:100%; border:none; max-width: 540px; height: 500px; "></iframe></div>');
            $('#restaurant-widget').fadeIn();
            return false;
        } else if ($(this).attr('href') == '#book-afternoontea') {
            $(this).before('<div id="restaurant-widget" ' + widgetStyles + '><a class="restaurant-widget-close" href="#" onclick="$(\'#restaurant-widget\').fadeOut(); return false;">Close</a><iframe src="https://booking.resdiary.com/widget/Standard/TheGreatHall/2674" allowtransparency="true" frameborder="0" style="width:100%; border:none; max-width: 540px; height: 500px; "></iframe></div>');
            $('#restaurant-widget').fadeIn();
            return false;
        }
    });
    $(document.body).on('click', '.offer-cta a, .offer-item a', function () {
        if ($(this).attr('href') == '#book-table') {
            $(this).before('<div id="restaurant-widget" style="top: -50px"><a class="restaurant-widget-close" href="#" onclick="$(\'#restaurant-widget\').fadeOut(); return false;">Close</a><iframe src="https://booking.resdiary.com/widget/Standard/ClivedenDiningRoom/325" allowtransparency="true" frameborder="0" style="width:100%; border:none; max-width: 540px; height: 500px; "></iframe></div>');
            $('#restaurant-widget').fadeIn();
            return false;
        } else if ($(this).attr('href') == '#book-table-astor') {
            $(this).before('<div id="restaurant-widget" style="top: -50px"><a class="restaurant-widget-close" href="#" onclick="$(\'#restaurant-widget\').fadeOut(); return false;">Close</a><iframe src="https://booking.resdiary.com/widget/Standard/TheAstorGrill/7647" allowtransparency="true" frameborder="0" style="width:100%; border:none; max-width: 540px; height: 500px; "></iframe></div>');
            $('#restaurant-widget').fadeIn();
            return false;
        } else if ($(this).attr('href') == '#book-table-boats') {
            $(this).before('<div id="restaurant-widget" style="top: -50px"><a class="restaurant-widget-close" href="#" onclick="$(\'#restaurant-widget\').fadeOut(); return false;">Close</a><iframe src="https://booking.resdiary.com/widget/Standard/ClivedenHouseFlotilla/7648" allowtransparency="true" frameborder="0" style="width:100%; border:none; max-width: 540px; height: 500px; "></iframe></div>');
            $('#restaurant-widget').fadeIn();
            return false;
        } else if ($(this).attr('href') == '#book-afternoontea') {
            $(this).before('<div id="restaurant-widget" style="top: -50px"><a class="restaurant-widget-close" href="#" onclick="$(\'#restaurant-widget\').fadeOut(); return false;">Close</a><iframe src="https://booking.resdiary.com/widget/Standard/TheGreatHall/2674" allowtransparency="true" frameborder="0" style="width:100%; border:none; max-width: 540px; height: 500px; "></iframe></div>');
            $('#restaurant-widget').fadeIn();
            return false;
        }
    });
    
    **/

    // Toggle search box
    // 	$('a.open-search').on('click', function () {
    // 		$(this).next().toggleClass('open');
    // 		return false;
    // 	});


    // Popup Gallery
    resizeGallery();
    $(window).resize(function () {
        waitForFinalEvent(function () {
            resizeGallery();
        }, 500, 'gallery-resize');
    });

    $('.open-gallery').on('click', function () {
        $('.gallery-wrapper').fadeIn();
        loadGallery($(this).attr('href'));
        return false;
    });

    $('.gallery-list-close').on('click', function () {
        $('.gallery-list').fadeOut();
        return false;
    });

    $('.gallery-wrapper-close').on('click', function () {
        $('.gallery-wrapper').fadeOut();
        $('.gallery-list').fadeIn();
        return false;
    });

    $('.gallery-list-inner ul a').on('click', function () {
        $('.gallery-list').fadeOut();
        $('.gallery-wrapper').fadeIn();
        return false;
    });

    $(document.body).on('click', '.gallery-nav a', function () {
        loadGallery($(this).attr('href'));
        return false;
    });



	
	
	// Eventopedia Videos
	popupVideos = [
		{
			'href': '#video-cliveden',
			'title': 'Cliveden House',
			'src': '//eventopedia.navstream.com/cliveden_house/#cliveden_click_explore',
			'width': '770',
			'height': '481'
		},
		{
			'href': '#video-waldos',
			'title': 'Waldo’s',
			'src': '//eventopedia.navstream.com/cliveden_house/#cliveden_waldos_clickexplore',
			'width': '770',
			'height': '481'
		},
		{
			'href': '#video-screen',
			'title': 'The Screening Room',
			'src': '//eventopedia.navstream.com/cliveden_house/#cliveden_screen_clickexplore',
			'width': '770',
			'height': '481'
		},
		{
			'href': '#video-french',
			'title': 'The French Dining Room',
			'src': '//eventopedia.navstream.com/cliveden_house/#cliveden_french_clickexplore',
			'width': '770',
			'height': '481'
		},
		{
			'href': '#video-churchill',
			'title': 'The Churchill',
			'src': '//eventopedia.navstream.com/cliveden_house/#cliveden_churchill_clickexplore',
			'width': '770',
			'height': '481'
		},
		{
			'href': '#video-boudoir',
			'title': 'The Boudoir & Tote',
			'src': '//eventopedia.navstream.com/cliveden_house/#clliveden_boudoir_clickexplore',
			'width': '770',
			'height': '481'
		},
		{
			'href': '#video-cellar',
			'title': 'The Cellar Dining Room',
			'src': '//eventopedia.navstream.com/cliveden_house/#cliveden_cellar_clickExplore',
			'width': '770',
			'height': '481'
		}
	];

	for (video in popupVideos) {
		$(document.body).on('click', 'a[href="' + popupVideos[video]['href'] + '"]', function () {
			var videoIndex = 100;
			for (v in popupVideos) {
				if ( $(this).attr('href') == popupVideos[v]['href'] ) {
					videoIndex = v;
				}
			}
			if (videoIndex == 100) {
				return;
			}
			$.fancybox({
				content: '<div class="video-popup-inner"><h1>' + popupVideos[videoIndex]['title'] + '</h1><div class="video-popup-iframe"><iframe type="text/html" width="' + popupVideos[videoIndex]['width'] + '" height="' + popupVideos[videoIndex]['height'] + '" frameborder="0" allowFullScreen allowTransparency="true" mozallowfullscreen webkitAllowFullScreen src="' + popupVideos[videoIndex]['src'] + '"></iframe></div></div>',
				wrapCSS: 'video-popup',
				autoSize : false,
				width: '60%',
				height: 'auto',
				beforeShow: function () {
					$('.fancybox-overlay').css('background-color', '#b3b3b6');
					//$('.fancybox-overlay').prepend( '<a href="#" onclick="$.fancybox.close(); return false;" class="video-popup-close">Close</a>' );
				}
			});
			return false;
		});
	}
	
	
	
	
	
	// Get the url parameters
	var urlParams = {};
	var e,
		a = /\+/g,  // Regex for replacing addition symbol with a space
		r = /([^&=]+)=?([^&]*)/g,
		d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
		q = window.location.search.substring(1);

	while (e = r.exec(q))
		urlParams[d(e[1])] = d(e[2]);

	setTimeout( function () {
		if (urlParams['gallery']) {
			console.log(urlParams['gallery']);
			$('a.left-off-canvas-toggle, a.open-gallery').trigger('click');
			setTimeout( function () {
				$('.gallery-nav a[href*="' + urlParams['gallery'] + '"]').trigger('click');
			}, 1000);
		}
	}, 1000);
			
	
	
});


function changeWidget() {
	var newValue = $('#booking-widget-choose').val();
	$('#booking-widget-rooms, #booking-widget-food, #booking-widget-gift').hide();
	$('#' + newValue).show();
}


// Trigger the next field to open
function openFieldAfter(field) {
	
	//console.log(field);
	
	// Skip over the replaced select box
	if ($(field).next().is('select')) {
		field = $(field).next();
	}

}


// Set the widget's position for large screens only
function positionWidget() {
	if ( $(window).innerWidth() > 680 ) {
		var fieldPositon = document.getElementById('arrive').getBoundingClientRect();
		var style = document.createElement('style');
		style.type = 'text/css';
		style.innerHTML = '.datepicker.datepicker-dropdown.dropdown-menu { top: ' + fieldPositon.y + 'px !important; left: ' + (fieldPositon.x - 231) + 'px !important; }';
		document.getElementsByTagName('head')[0].appendChild(style);
	}
}




function loadGallery(url) {
    $('#gallery-container').load(url, function () {


        $('.gallery-thumbs').on('init', function (event, slick) {
	        //$('.gallery-slider-full.active').html('<img src="' + $('.gallery-thumbs .slick-current').attr('src') + '">').addClass('active');
	        $('.gallery-slider-full.active').css('background-image', 'url(' + $('.gallery-thumbs .slick-current img').attr('src') + ')');
			$('.gallery-slide-caption').text($('.gallery-thumbs .slick-current img').attr('title'));
        });
        
        
        $('.gallery-thumbs').slick({
	        slidesToShow: 7,
	        arrows: false,
	        infinite: false,
	        focusOnSelect: true,
	        responsive: [
		        {
			        breakpoint: 1024,
			        settings: {
				        slidesToShow: 6
			        }
		        },
		        {
			        breakpoint: 640,
			        settings: {
				        slidesToShow: 4
			        }
		        }
	        ]
        });
        
        $('.gallery-thumbs .gallery-thumb-item').on('click', function () {
	        //console.log('Chaning to ', $(this).data('slick-index'));
	        $('.gallery-thumbs').slick('slickGoTo', $(this).data('slick-index'), true);
	        
	        $('.gallery-thumbs .slick-current').removeClass('slick-current'); // Hack around Slick's deficiencies
	        $( $('.gallery-thumbs .gallery-thumb-item').get(  $(this).data('slick-index'))  ).addClass('slick-current'); // Manually changing the visible item
	        
	        //$('.gallery-slider-full.inactive').html('<img src="' + $('.gallery-thumbs .slick-current').attr('src') + '">');
	        $('.gallery-slider-full.inactive').css('background-image', 'url(' + $('.gallery-thumbs .slick-current img').attr('src') + ')');
	        $('.gallery-slider-full').toggleClass('active inactive');
			
			$('.gallery-slide-caption').text($('.gallery-thumbs .slick-current img').attr('title'));
        });
        
        
        $('.gallery-slider .slick-prev').on('click', function () {
	        var currentSlideIndex = $('.gallery-thumbs .slick-current').index();
	        
	        // Cancel if already at start
	        if (currentSlideIndex == 0) {
		        return;
	        }
	        
	        //console.log('Previousing to ', currentSlideIndex - 1, ' from ', currentSlideIndex);
	        
	        $('.gallery-thumbs').slick('slickGoTo', currentSlideIndex - 1, false);
	        
	        $('.gallery-thumbs .slick-current').removeClass('slick-current'); // Hack around Slick's deficiencies
	        $( $('.gallery-thumbs .gallery-thumb-item').get(  currentSlideIndex - 1)  ).addClass('slick-current'); // Manually changing the visible item
	        
	        //$('.gallery-slider-full.inactive').html('<img src="' + $('.gallery-thumbs .slick-current').attr('src') + '">');
	        $('.gallery-slider-full.inactive').css('background-image', 'url(' + $('.gallery-thumbs .slick-current img').attr('src') + ')');
	        $('.gallery-slider-full').toggleClass('active inactive');
			
			$('.gallery-slide-caption').text($('.gallery-thumbs .slick-current img').attr('title'));
        });
        
        
        $('.gallery-slider .slick-next').on('click', function () {
	        var currentSlideIndex = $('.gallery-thumbs .slick-current').index();
	        
	        // Cancel if already at end
	        if (currentSlideIndex >= $('.gallery-thumbs img').length - 1) {
		        return;
	        }
	        
	        //console.log('Nexting to ', currentSlideIndex + 1, ' from ', currentSlideIndex);
	        
	        $('.gallery-thumbs').slick('slickGoTo', currentSlideIndex + 1, false);
	        
	        $('.gallery-thumbs .slick-current').removeClass('slick-current'); // Hack around Slick's deficiencies
	        $( $('.gallery-thumbs .gallery-thumb-item').get(  currentSlideIndex + 1)  ).addClass('slick-current'); // Manually changing the visible item
	        
	        //$('.gallery-slider-full.inactive').html('<img src="' + $('.gallery-thumbs .slick-current').attr('src') + '">');
	        $('.gallery-slider-full.inactive').css('background-image', 'url(' + $('.gallery-thumbs .slick-current img').attr('src') + ')');
	        $('.gallery-slider-full').toggleClass('active inactive');
			
			$('.gallery-slide-caption').text($('.gallery-thumbs .slick-current img').attr('title'));
        });


    });
}


function resizeGallery() {
	if (matchMedia('only screen and (max-width: 640px)').matches) { // mobile
		$('.gallery-wrapper').css('width', $(window).innerWidth() + 'px');
	} else { // desktop + tablet
    	$('.gallery-list, .gallery-wrapper').css('height', (window.innerHeight - 92) + 'px');
    	$('.gallery-wrapper').css('width', ($(window).innerWidth() - 80) + 'px');
	}
}

// http://stackoverflow.com/questions/2854407/javascript-jquery-window-resize-how-to-fire-after-the-resize-is-completed
var waitForFinalEvent = (function () {
    var timers = {};
    return function (callback, ms, uniqueId) {
        if (!uniqueId) {
            uniqueId = "Don't call this twice without a uniqueId";
        }
        if (timers[uniqueId]) {
            clearTimeout(timers[uniqueId]);
        }
        timers[uniqueId] = setTimeout(callback, ms);
    };
})();



function loadLeftAndRightPages() {
    var nextPage = $('.detail-content-container').find('.detail-next').first().attr('href') + '?ajax=true';
    var prevPage = $('.detail-content-container').find('.detail-prev').first().attr('href') + '?ajax=true';

    if (nextPage != 'undefined?ajax=true') {
        $.get(nextPage, function (nextHtml) {
            $('.detail-content-container.slick-current').next().html(nextHtml);
            setupSlider($('.detail-content-container.slick-current').next().find('.detail-slider'));
        });
    }

    if (prevPage != 'undefined?ajax=true') {
        $.get(prevPage, function (prevHtml) {
            $('.detail-content-container.slick-current').prev().html(prevHtml);
            setupSlider($('.detail-content-container.slick-current').prev().find('.detail-slider'));
        });
    }
}

function loadNextPageAfterSlide() {
    if ($('.detail-content-container.slick-current').next().text() == '') {
        var nextPage = $('.detail-content-container.slick-current').find('.detail-next').first().attr('href');
        $('.detail-content-container.slick-current').next().load(nextPage + '?ajax=true', function () {
            setupSlider($('.detail-content-container.slick-current').next().find('.detail-slider'));
        });

    }
}

function loadPrevPageAfterSlide() {
    if ($('.detail-content-container.slick-current').prev().text() == '') {
        var prevPage = $('.detail-content-container.slick-current').find('.detail-prev').first().attr('href');
        $('.detail-content-container.slick-current').prev().load(prevPage + '?ajax=true', function () {
            setupSlider($('.detail-content-container.slick-current').prev().find('.detail-slider'));
        });
    }
}

function setupSlider(el) {
    $(el).slick({
        dots: false,
        fade: true,
        arrows: true,
        autoplay: true,
        speed: 1000,
		autoplaySpeed: 5000,
        pauseOnHover: false
    });

    /*$(el).on('init', function () {
		var current = $('#detail-slider').slickCurrentSlide();
		$('#detail-slider').slickGoTo(current);
		console.log('Slider inited');
	});*/
}









// http://stackoverflow.com/questions/2255291/print-the-contents-of-a-div
function PrintElem(elem) {
    Popup($(elem).html());
}

function Popup(data) {
    var mywindow = window.open('', 'my div', 'height=400,width=600');
    mywindow.document.write('<html><head><title>my div</title>');
    mywindow.document.write('<style type="text/css">.favourites-delete, .favourites-book, .favourites-share, .favourites-sign-out, .favourites-profile-links { display: none; }</style>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(data);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10

    mywindow.print();
    mywindow.close();

    return true;
}



// Close the favourites popups when clicked outside of
$(document).mouseup(function (e) {
    var container = ['#favourites-panel', '.favourites-login'];

    $.each(container, function (key, value) {
        if (!$(value).is(e.target) // if the target of the click isn't the container...
            && $(value).has(e.target).length === 0) // ... nor a descendant of the container
        {
            $(value).hide();
        }
    });
});




window.matchMedia = window.matchMedia || (function( doc, undefined ) {

  "use strict";

  var bool,
      docElem = doc.documentElement,
      refNode = docElem.firstElementChild || docElem.firstChild,
      // fakeBody required for <FF4 when executed in <head>
      fakeBody = doc.createElement( "body" ),
      div = doc.createElement( "div" );

  div.id = "mq-test-1";
  div.style.cssText = "position:absolute;top:-100em";
  fakeBody.style.background = "none";
  fakeBody.appendChild(div);

  return function(q){

    div.innerHTML = "&shy;<style media=\"" + q + "\"> #mq-test-1 { width: 42px; }</style>";

    docElem.insertBefore( fakeBody, refNode );
    bool = div.offsetWidth === 42;
    docElem.removeChild( fakeBody );

    return {
      matches: bool,
      media: q
    };

  };

}( document ));


